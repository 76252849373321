export const ANNOUNCEMENT_TOAST = "announcement-toast";
export const COMMIT_CHART_TYPE_VIEW_OPTIONS_ACCORDION =
  "commit-chart-view-options-accordion";
export const CURRENT_PROJECT = "mci-project-cookie";
export const CY_DISABLE_COMMITS_WELCOME_MODAL =
  "cy-disable-commits-welcome-modal";
export const CY_DISABLE_NEW_USER_WELCOME_MODAL =
  "cy-disable-new-user-welcome-modal";
export const DISABLE_QUERY_POLLING = "disable-query-polling";
export const getNotificationTriggerCookie = (type: string) =>
  `${type}-notification-trigger`;
export const HIDE_FEEDBACK = "HIDE_FEEDBACK";
export const INCLUDE_COMMIT_QUEUE_PROJECT_PATCHES =
  "include-commit-queue-project-patches";
export const INCLUDE_HIDDEN_PATCHES = "include-hidden-patches";
export const SLACK_NOTIFICATION_BANNER = "has-closed-slack-banner";
export const SUBSCRIPTION_METHOD = "subscription-method";
export const SEEN_IMAGE_VISIBILITY_GUIDE_CUE =
  "seen-image-visibility-guide-cue";
export const SEEN_TEST_ANALYSIS_TAB_GUIDE_CUE =
  "seen-test-analysis-tab-guide-cue";
